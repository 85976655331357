// import styles from "./HomePage.module.scss";
import HeroSection from "./HeroSection";

const HomePage = () => {
    return (
        <main>
            <HeroSection />
        </main>
    );
};

export default HomePage;